import { Box, Skeleton, styled } from "@mui/material";
import { useState } from "react";

const GuidelineBox = styled(Box)({
    width: "100%",
    height: "20rem",
    marginBottom: "0.5rem"
}); 

const GuidelineImg = styled('img')({
    width: "100%",
    height: "100%",
    borderRadius: "5px",
    display: "block",
    objectFit: "cover"
}); 

const StyledSkeleton = styled(Skeleton)({
    width: "100%",
    height: "22rem",
    borderRadius: "5px",
    display: "block",
})

interface IGuidelineImageProps {
    src: string
}

const GuidelineImage: React.FC<IGuidelineImageProps> = (props) => {

    const [loaded, setLoaded] = useState(false)

    return (
        <GuidelineBox>
            {loaded ? null : <StyledSkeleton variant="rectangular"/>}
            <GuidelineImg
                style={{display: loaded ? "block" : "none"}}
                src={props.src} onLoad={() => setLoaded(true)}
                alt=""
            />
        </GuidelineBox>
    )
}

export default GuidelineImage