import React from "react";
import faceModalityImage from "../../assets/modalityGuidelinesImages/faceModality.jpg"
import voiceModalityImage from "../../assets/modalityGuidelinesImages/voiceModality.jpg"
import { Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTheme } from '@mui/material/styles';
import GuidelineImage from "./GuidelineImage";
import { styled } from "@mui/material";

const useStyles = makeStyles((theme: { spacing: (arg0: number) => any; }) => ({
    Button: {
        marginTop: useTheme().spacing(3),
        width: "20rem",
        height: "6rem",
        borderRadius: "15px"
    },
    textTitle: {
        fontWeight: 500,
        fontSize: "1.25rem",
        color: "#303030"
    },
    text: {
        fontWeight: 400,
        fontSize: "1rem",
        color: "#303030"
    }
}));

const GridImage = styled(Grid)({
    paddingLeft: "2rem",
    paddingRight: "2rem",
    "&.MuiGrid-item": {
        paddingLeft: "2rem"
    }
}); 

const GeneralGuidelines: React.FC = () => {

    const classes = useStyles();
    
    return (
        <Grid container spacing={3}>
            <GridImage item md={6} xs={12}>
                <GuidelineImage src={faceModalityImage}/>
                <Typography className={classes.textTitle}>
                    Face modality
                </Typography>
                <Typography className={classes.text}>
                    Captured via camera with optional liveness and ICAO compliance checks. Enrollment, verification and check operations are supported on the face modality.
                </Typography>
            </GridImage>
            <GridImage item md={6} xs={12}>
                <GuidelineImage src={voiceModalityImage}/>
                <Typography className={classes.textTitle}>
                    Voice modality
                </Typography>
                <Typography className={classes.text}>
                    Captured via microphone. Can be chosen for enrollment and later for user authentication.
                </Typography>
            </GridImage>
        </Grid>
    );
}

export default GeneralGuidelines;